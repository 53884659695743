import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(LanguageDetector).use(initReactI18next).init({
  debug: true,
  lng: "ru",
  resources: {
    en: {
      translation: {
        about: 'About us',
        services: 'Services',
        doctors: 'Doctors',
        news: 'News',
        team: 'Our team',
        media: 'Media',
        contact: 'Contact',
        results: 'Test Results',
        phone: 'Phone',
        address: 'Address',
        region: 'Tashkent region, Kibrai district, Salar urban settlement, Gulimamur Street, 5/1',
        social: 'Social Network',
        subscribe: 'Subscribe',
        call: 'Call us',
        diagnostics: 'Diagnostics',
        hospital: 'Hospital',
        children: 'Children department',
        ourservice: 'Our Service',
        peditarion: 'The pediatrician at our clinic works around the clock, 24/7.',
        carouselText: 'The best specialists at our clinic will conduct an examination and prescribe effective treatment.',
        more: 'More',
        functional: 'Functional diagnostics',
        instrumental: 'Instrumental methods of research and diagnosis of diseases',
        surgery: 'Surgery',
        treatment: 'Treatment of acute and chronic diseases by operative method',
        hospital: 'Hospital',
        branch: 'Our branch is equipped with advanced technologies The Hospital "Hayat Hospital" offers a unique project for the preservation of health for the population',
        endacri: 'Endocrinology',
        neurology: 'Neurology',
        allerg: 'Allergology',
        pulmonolog: 'Pulmonology',
        otolor: 'Otolaryngology',
        gastro: 'Gastroenterology',
        oftol: 'Ophthalmology',
        plastic: 'Plastic Surgery',
        heart: 'Cardiology',
        urolog: 'Urology',
        ginekolog: 'Gynecology',
        lab: 'Laboratory',
        vasc: 'Vascular Surgery',
        diagnos: 'Diagnosis',
        specialist: 'Our Specialists',
        ourdoctors: 'Our Doctors',
      },
    },
    ru: {
      translation: {
        about: 'О нас',
        services: 'Услуги',
        doctors: 'Bрачи',
        news: 'Новости',
        team: 'Наша команда',
        media: 'Медиа',
        contact: 'Контакты',
        results: 'Результаты анализов',
        phone: 'Tелефон',
        address: 'Адрес',
        region: 'Ташкентская область, Кибрайский район, городской посёлок Салар, улица Гулимамур, 5/1',
        social: 'Социальная сеть',
        subscribe: 'Подписаться',
        call: 'Позвоните нам',
        diagnostics: 'Диагностика',
        hospital: 'Больница',
        children: 'Детское отделение',
        ourservice: 'Наш сервис',
        peditarion: 'Педиатр в нашей клинике работает круглосуточно, 24/7.',
        carouselText: 'Лучшие специалисты нашей клиники проведут обследование и назначат эффективное лечение.',
        more: 'Ещё',
        functional: 'Функциональная диагностика',
        instrumental: 'Инструментальные методы исследования и диагностики заболеваний',
        surgery: 'Хирургия',
        treatment: 'Лечение острых и хронических заболеваний оперативным методом',
        hospital: 'Больница',
        branch: 'Наш филиал оснащен передовыми технологиями. Больница «Hayat Hospital» предлагает уникальный проект по сохранению здоровья населения.',
        endacri: ' Эндокринология',
        neurology: 'Неврология',
        allerg: 'Аллергология',
        pulmonolog: 'Пульмонология',
        otolor: 'Отоларингология',
        gastro: 'Гастроэнтерология',
        oftol: 'Офтальмология',
        plastic: 'Пластическая хирургия ',
        heart: 'Кардиология',
        urolog: 'Урология',
        ginekolog: 'Гинекология',
        lab: 'Лаборатория',
        vasc: 'Сосудистая хирургия',
        diagnos: 'Диагностика',
        specialist: 'Наши специалисты',
        ourdoctors: 'Наши врачи',
      }
    },
    uz: {
      translation: {
        about: 'Biz haqimizda',
        services: 'Xizmatlar',
        doctors: 'Shifokorlar',
        news: "Yangiliklar",
        team: 'Bizning jamoamiz',
        media: 'OAV',
        contact: "Aloqa",
        results: 'Tahlil natijalari',
        phone: 'Telefon',
        address: 'Manzil',
        region: "Toshkent viloyati, Qibray tumani, Salar shaharchasi, Gulimamur ko'chasi, 5/1",
        social: 'Ijtimoiy tarmoq',
        subscribe: "Obuna bo'ling",
        call: "Qo‘ng‘iroq qiling",
        diagnostics: 'Diagnostika',
        hospital: 'Kasalxona',
        children: "Bolalar bo'limi",
        ourservice: 'Bizning xizmatimiz',
        peditarion: 'Bizning klinikamizda pediatr kunu tun, 24/7 ishlaydi.',
        carouselText: "Klinikamizning eng yaxshi mutaxassislari tekshiruv o'tkazib, samarali davolanishni tayinlaydilar.",
        more: "Batasfil",
        functional: "Funksional diagnostika",
        instrumental: "Kasalliklarni tadqiq qilish va diagnostika qilishning asbob-uskunaviy usullari",
        surgery: 'Jarrohlik',
        treatment: 'O‘tkir va surunkali kasalliklarni operatsion usulda davolash',
        hospital: 'Shifoxona',
        branch: 'Bizning filialimiz ilg‘or texnologiyalar bilan jihozlangan. "Hayat Hospital" shifoxonasi aholi salomatligini saqlash uchun noyob loyihani taklif etadi.',
        endacri: 'Endokrinologiya',
        neurology: 'Nevrologiya',
        allerg: 'Allergologiya',
        pulmonolog: 'Pulmonologia',
        otolor: 'Otorinolaringologiya',
        gastro: 'Gastroenterologiya',
        oftol: 'Oftalmologiya',
        plastic: 'Plastik jarrohlik',
        heart: 'Kardiologiya',
        urolog: 'Urologiya',
        ginekolog: 'Ginekologiya',
        lab: 'Laboratoriya',
        vasc: 'Tomir jarrohligi',
        diagnos: 'Diagnostika',
        specialist: 'Bizning mutaxassislarimiz',
        ourdoctors: 'Bizning shifokorlarimiz',
      }
    },
  }
})

export default i18n;