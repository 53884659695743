import React from 'react';

function Media() {
  return (
    <div>
      <h2>Media</h2>
      <p>This is a section where I tell people about myself.</p>
    </div>
  );
}

export default Media;
