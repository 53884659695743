import React from 'react';

function Doctors() {
  return (
    <div>
      <h2>Doctors</h2>
      <p>This is a section where I tell people about myself.</p>
    </div>
  );
}

export default Doctors;
