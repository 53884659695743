import React from 'react';

function News() {
  return (
    <div>
      <h2>News</h2>
      <p>This is a section where I tell people about myself.</p>
    </div>
  );
}

export default News;
